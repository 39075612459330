<template>
  <div id="content" class="container">
    <div class="row">
      <div class="col-12">
        <h1>Outils</h1>
      </div>
    </div>
    <div class="row outils">
      <div class="col-12">
        <div v-for="(outil, index) in outils" :key="index" class="outil">
          <div class="outil-head">
            <img
              :alt="outil.titre"
              :src="require(`@/assets/img/${outil.img}`)"
              width="220"
            />
            <span>{{ outil.titre }}</span>
          </div>
          <a :href="outil.url" target="_blank">{{ outil.url_display }}</a>
          <p>{{ outil.definition }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Outils",
  data() {
    return {
      outils: [
        {
          img: "outils-dotdigital.png",
          titre: "Dot Digital",
          url: "https://r2-app.dotdigital.com/",
          url_display: "https://r2-app.dotdigital.com",
          definition:
            "Votre outil d'activation. Il permet d'envoyer des newsletters à vos bases CRM et d'engager vos fans.",
        },
        {
          img: "outils-formbuilder.png",
          titre: "Form Builder",
          url: "https://forms.umusic-online.com/builder/login?redirect=%2Fdocumentation",
          url_display: "https://forms.umusic-online.com",
          definition:
            "L'outil qui permet de générer vos ID CRM. Il est la passerelle entre Mira et Dot Digital.",
        },
        {
          img: "outils-mira.png",
          titre: "Mira",
          url: "https://marketer.mira.umusic.com/apps/mira/campaigns",
          url_display: "https://marketer.mira.umusic.com",
          definition:
            "Votre outil d'acquisition. Il permet de créer des campagnes (simple/custom) et de recruter des adresses mails/sms.",
        },
        {
          img: "outils-movableink.png",
          titre: "Movable Ink",
          url: "https://app.movableink.com/",
          url_display: "https://app.movableink.com",
          definition:
            "L'outil qui permet de dynamiser vos newsletters. Il vous suffit de créer un block et d'intégrer le code dans votre newsletter sur Dot Digital.",
        },
        {
          img: "outils-shopify.png",
          titre: "Shopify +",
          url: "https://accounts.shopify.com/store-login",
          url_display: "https://accounts.shopify.com",
          definition:
            "Votre support pour la création de vos sites artistes (ventes en ligne).",
        },
        {
          img: "outils-linkfire.png",
          titre: "Linkfire",
          url: "https://www.linkfire.com/",
          url_display: "https://www.linkfire.com",
          definition:
            "Cet outil vous permet d'avoir un espace marketing sur une page optimal renvoyant vers des liens importants (sortie single, album, store, concerts...).",
        },
        {
          img: "outils-regmaker.png",
          titre: "Regmaker",
          url: "https://crm.universalmusic.fr/reglement/",
          url_display: "https://crm.universalmusic.fr/reglement/",
          definition:
            "Générer un règlement officielle UMF pour vos jeux-concours avec dotation (prix/gains).",
        },
        {
          img: "outils-gifmaker.png",
          titre: "Gif Maker",
          url: "https://youtube-en-gif.pdf-convertisseur.fr/?utm_source=dotmailer&utm_medium=email&utm_campaign=306888_NL%20CRM%20du%20mois%20-%20F%C3%A9vrier_340973_FR&mi_ecmp=campaign.id%20&mi_u=contact.data.email%20%20md5%20&dm_i=7B3H,6KSO,2C50FQ,X0DR,1",
          url_display: "https://youtube-en-gif.pdf-convertisseur.fr/",
          definition: "Créer un GIF à partir d’une vidéo Youtube / logo en PJ.",
        },
        {
          img: "outils-pipette.jpeg",
          titre: "Outil Pipette Couleur",
          url: "https://chrome.google.com/webstore/detail/ultimate-color-picker/lobiadjfmkomemokdfhiaaaidgdhcded",
          url_display: "https://chrome.google.com/ultimate-color-picker",
          definition:
            "Grâce à cette extension google vous pourrez retrouver les codes couleurs de n’importe quel site et travailler plus aisément sur vos designs.",
        },
        {
          img: "outils-iloveimg.png",
          titre: "I LOVE IMG",
          url: "https://www.iloveimg.com/compress-image",
          url_display: "https://www.iloveimg.com/",
          definition:
            "Cet outil vous permet de compresser, recadrer, convertir et redimensionner les images.",
        },
        {
          img: "outils-removebg.png",
          titre: "Remove BG",
          url: "https://www.remove.bg/fr",
          url_display: "https://www.remove.bg/fr",
          definition:
            "Remove bg est une solution gratuite, simple et rapide pour supprimer le fond de son image. Si vous souhaitez intégrer un logo ou une image dans votre newsletter, vous pouvez obtenir une image avec un fond transparent sans passer par photoshop.",
        },
      ],
    };
  },
  mounted() {
    this.$store.state.app_id = "outils";
  },
};
</script>

<style lang="scss">
#outils {
  #content {
    padding-top: 180px;

    & > div:first-child {
      background: url("../assets/img/contact-background.svg") no-repeat bottom
        right;
      background-size: contain;
    }
  }

  h1 {
    font-weight: 900;
    line-height: 103%;
    color: $blue-umf;
    margin-bottom: 40px;
  }

  .outils {
    background-color: #ededed;
    color: $blue-umf;
    padding: 33px;

    .letter {
      display: flex;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 140%;
      margin-bottom: 20px;
    }

    .outil {
      background-color: #ffffff;
      border-radius: 30px;
      padding: 26px 32px;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 133%;
      word-wrap: break-word;

      .outil-head {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        img {
          margin-right: 20px;
        }

        span {
          display: none;
          font-weight: 700;
          font-size: 2rem;
          line-height: 140%;
          margin-bottom: 5px;
        }
      }

      &:nth-child(9) {
        img {
          width: 50px;

          & + span {
            display: flex;
          }
        }
      }

      a {
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  .top {
    display: block;
    width: 72px;
    height: 72px;
    background: $green-umf url("../assets/img/arrow-top.svg") no-repeat center
      center;
    cursor: pointer;
    margin: 33px auto 0;
    border-radius: 72px;
  }
}

@include media-breakpoint-down(lg) {
  #outils {
    #content {
      & > div:first-child {
        background-size: contain;
        margin-bottom: 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-up(md) {
  #outils {
    .outils {
      border-radius: 30px;
      padding: 50px;

      .outil {
        padding: 38px 78px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #outils {
    #content {
      & > div:first-child {
        padding-bottom: 40px;
      }
    }

    h1 {
      margin-bottom: 100px;
    }

    .outils {
      position: relative;
      top: -20px;
    }
  }
}
</style>
