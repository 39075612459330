import { createStore } from "vuex";

export default createStore({
  state: {
    app_id: "",
    search_back: "/tutos",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
