<template>
  <div id="content" class="container">
    <div class="row">
      <div class="col-12">
        <h1>Lexique</h1>
      </div>
    </div>
    <div class="row definitions">
      <div class="col-12">
        <div v-for="(letter, index) in lexique" :key="index">
          <span class="letter">{{ letter.letter }}</span>
          <div
            v-for="(definition, index) in letter.definitions"
            :key="index"
            class="definition"
          >
            <span>{{ definition.titre }}</span>
            <span v-html="definition.contenu"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="top" @click.prevent="scrollTop"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Lexique",
  data() {
    return {
      lexique: [
        {
          letter: "A",
          definitions: [
            {
              titre: "Addressbook",
              contenu:
                "Anglais pour « base », là où sont recueillis les contacts suite à une inscription à une newsletter.",
            },
            {
              titre: "Add to collection",
              contenu:
                "Ajout <b>d’une playlist</b>, d’un titre ou d’un album déjà sorti dans la bibliothèque du fan via les fonctions “save tracks” ou “save album” des boutons DSP dans Mira.",
            },
          ],
        },
        {
          letter: "C",
          definitions: [
            {
              titre: "CRM",
              contenu:
                "Customer Relationship Management. Chez Universal, il traduit tous les aspects de communication / relation au fan en mail ou sms : annonce de sortie d’album, drop de merch, etc.",
            },
          ],
        },
        {
          letter: "D",
          definitions: [
            {
              titre: "DAM",
              contenu:
                "Digital Account Manager, la plateforme qui recense les différentes bases dans les différents compte DD.",
            },
            {
              titre: "DD",
              contenu:
                "Dot digital, notre « ESP » (email service provider) donc plateforme d’envoi de newsletter mais qui sert aussi à la segmentation.",
            },

            {
              titre: "Données AE",
              contenu:
                "L’audience affinitaire (AE) de Deezer est une analyse des artistes similaires à votre artiste en fonction de la consommation de musique. Ainsi nous récupérons les optin UMG de cette analyse pour vous créer un segment à utiliser sous le nom d’expéditeur de “Universal Music France”. Il vous suffit de dupliquer votre newsletter, d’en envoyer une sous le nom d’expéditeur de votre artiste (Base Artiste) et une autre sous le nom d’expéditeur de Universal Music France (Segment AE).",
            },
          ],
        },
        {
          letter: "F",
          definitions: [
            {
              titre: "Form Builder",
              contenu:
                "Outil pour faire la liaison entre la base de donnée de votre artiste et les nouvelles acquisitions sur le formulaire Mira.",
            },
          ],
        },
        {
          letter: "M",
          definitions: [
            {
              titre: "Movable Ink",
              contenu:
                "Movable Ink : solution de personnalisation 1:1 pour les newsletters.",
            },
            {
              titre: "Mira",
              contenu:
                "Outil d'acquisition, qui vous permet de recruter de la data. Cela se traduit par la création de Landing page avec un formulaire de contacts (il est possible de faire des presaves, concours...).",
            },
          ],
        },
        {
          letter: "P",
          definitions: [
            {
              titre: "Pré-header",
              contenu:
                "Il s'agit de la ligne grisée qui vient après l’objet d’un email. Il se situe en haut du contenu de l'email, dans son en-tête. C’est la première phrase du corps de l’email qui apparaît en aperçu dans la boîte de réception du destinataire. Cela permet d’afficher un teasing de votre newsletter lors de l’affichage du mail dans la boîte de réception.",
            },
            {
              titre: "Presave",
              contenu:
                "Ajout d’un titre ou d’un album qui n’est pas encore sur les plateformes dans la bibliothèque du fan via les fonctions “save tracks” ou “save album” des boutons DSP dans Mira.",
            },
          ],
        },
        {
          letter: "S",
          definitions: [
            {
              titre: "Segment",
              contenu:
                "Ce sont des données qui ont été travaillées selon différents critères (exclusion d’une base, audiences affinitaires…).",
            },
            {
              titre: "Stream to unlock",
              contenu:
                "<b>Mise en avant</b> d’un extrait de 30s ou plus <a href='https://presave.umusic.com/blackpink-stream-to-unlock' target='_blank'>(Exemple avec Blackpink)</a> <b>que le fan doit</b> écouter pour accéder à la page suivante de la campagne. Ce widget est disponible uniquement sur Apple et Spotify.",
            },
          ],
        },
        {
          letter: "T",
          definitions: [
            {
              titre: "Taux d'ouverture (TO)",
              contenu:
                "Nombre d'emails ouverts par rapport au nombre total d'emails délivrés.",
            },
            {
              titre: "Taux de réactivité (CTO)",
              contenu: "Nombre de clics / Nombre d'emails ouverts.",
            },
            {
              titre: "Taux de délivrabilité",
              contenu:
                "La proportion des messages email considérés comme réellement parvenus à leur destinataire lors d'une campagne.",
            },
            {
              titre: "Track Drop",
              contenu:
                "Mise en place d’une <b>pre-save</b> sur <b>le long terme</b>. Les titres que vous aurez ajoutés précédemment resteront enregistrés. Ainsi lors d’une nouvelle pre-save, <b>le nouveau titre</b> et <b>l’historique des titres</b> seront ajoutés dans la bibliothèque.",
            },
          ],
        },
      ],
    };
  },
  methods: {
    scrollTop: function () {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.$store.state.app_id = "lexique";
  },
};
</script>

<style lang="scss">
#lexique {
  #content {
    padding-top: 180px;

    & > div:first-child {
      background: url("../assets/img/contact-background.svg") no-repeat bottom
        right;
      background-size: contain;
    }
  }

  h1 {
    font-weight: 900;
    line-height: 103%;
    color: $blue-umf;
    margin-bottom: 40px;
  }

  .definitions {
    background-color: #ededed;
    color: $blue-umf;
    padding: 33px;

    .letter {
      display: flex;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 140%;
      margin-bottom: 20px;
    }

    .definition {
      background-color: #ffffff;
      border-radius: 30px;
      padding: 26px 32px;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 133%;

      & > span:first-child {
        display: flex;
        font-weight: 700;
        font-size: 2rem;
        line-height: 140%;
        margin-bottom: 5px;
      }
    }
  }

  .top {
    display: block;
    width: 72px;
    height: 72px;
    background: $green-umf url("../assets/img/arrow-top.svg") no-repeat center
      center;
    cursor: pointer;
    margin: 33px auto 0;
    border-radius: 72px;
  }
}

@include media-breakpoint-down(lg) {
  #lexique {
    #content {
      & > div:first-child {
        background-size: contain;
        margin-bottom: 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-up(md) {
  #lexique {
    .definitions {
      border-radius: 30px;
      padding: 50px;

      .definition {
        padding: 38px 78px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #lexique {
    #content {
      & > div:first-child {
        padding-bottom: 40px;
      }
    }

    h1 {
      margin-bottom: 100px;
    }

    .definitions {
      position: relative;
      top: -20px;
    }
  }
}
</style>
