import { createRouter, createWebHistory } from "vue-router";
import Homepage from "@/views/Homepage";
import { Tutos } from "@/views/Tutos";
import Outils from "@/views/Outils";
import Lexique from "@/views/Lexique";
import Contact from "@/views/Contact";
import Login from "@/views/Login";
import axios from "axios";

const routes = [
  {
    path: "/auth/:token",
    name: "Auth",
  },
  {
    path: "/connexion",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Homepage",
    component: Homepage,
  },
  {
    path: "/tutos",
    name: "Tutos",
    component: Tutos.TutosView,
    children: [
      {
        path: ":categorie?",
        name: "TutosList",
        component: Tutos.TutosList,
      },
      {
        path: "recherche",
        name: "TutosSearch",
        component: Tutos.TutosSearch,
      },
    ],
  },
  {
    path: "/tuto/:slug",
    name: "TutoView",
    component: Tutos.TutoView,
  },
  {
    path: "/outils",
    name: "Outils",
    component: Outils,
  },
  {
    path: "/lexique",
    name: "Lexique",
    component: Lexique,
  },
  {
    path: "/contacts",
    name: "Contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "Auth") {
    localStorage.setItem("token", to.params.token);
    next({ name: "Homepage" });
  } else {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");

    if (to.name !== "Login" && !localStorage.getItem("token")) {
      next({ name: "Login" });
    } else if (to.name !== "Login" && localStorage.getItem("token")) {
      axios
        .get(process.env.VUE_APP_BACKEND_ROOT + "/user_check")
        .then((response) => {
          !response.data ? next({ name: "Login" }) : next();
        });
    } else next();
  }
});

router.afterEach((to) => {
  const route =
    to.matched[0].name === "TutoView" ? "Tutos" : to.matched[0].name;
  const link = document.querySelector(
    ".desktop nav a[data-ref='" + route + "']"
  );
  const link_active_left = Math.round(link.getBoundingClientRect().left);
  const nav = document.querySelector("nav");
  const ul = document.querySelector("ul");
  const ul_left = ul.getBoundingClientRect().left;

  nav.style.setProperty(
    "--ul-after-left",
    Math.round(link_active_left) - Math.round(ul_left) + "px"
  );
});

export default router;
