import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

const app = createApp(App);

// Axios en variable globale
app.config.globalProperties.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API_ROOT;

app.use(store).use(router).mount("body");

import "bootstrap/dist/js/bootstrap.min";
import "bootstrap-icons/font/bootstrap-icons.css";
