<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div id="ref_sizes_col_12"></div>
      </div>
      <div class="col-6">
        <div id="ref_sizes_col_6"></div>
      </div>
    </div>
  </div>
  <div :id="this.$store.state.app_id">
    <Header></Header>
    <router-view />
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: "App",
  components: {
    Header,
  },
  data() {
    return {
      width_col_6: null,
    };
  },
  created() {
    document.title = process.env.VUE_APP_TITLE;
  },
  mounted() {
    this.resizeTheme();
    window.addEventListener("resize", this.resizeTheme);
  },
  methods: {
    resizeTheme: function () {
      const sizes_col_12 = document
        .querySelector("#ref_sizes_col_12")
        .getBoundingClientRect();

      const sizes_col_6 = document
        .querySelector("#ref_sizes_col_6")
        .getBoundingClientRect();

      if (Math.round(sizes_col_6.width) !== this.width_col_6) {
        document.body.style.setProperty(
          "--ref-width-col-12",
          Math.round(sizes_col_12.width) + "px"
        );

        this.width_col_6 = Math.round(sizes_col_6.width);
        document.body.style.setProperty(
          "--ref-width-col-6",
          Math.round(sizes_col_6.width) + "px"
        );
        this.resizeTheme();
      }
    },
  },
};
</script>

<style lang="scss">
/* HTML / BODY */
html {
  height: 100%;
  background-color: $blue-umf;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

body {
  min-height: 100%;
  background-color: #ffffff;
  padding-bottom: 33px;
  border-bottom: 45px solid $blue-umf;
}

#content {
  padding-top: 144px;
}

*:focus {
  outline: none;
}

/* THEMES */
.theme {
  .theme-content {
    position: relative;
    border-radius: 10px;

    &:before {
      display: flex;
      width: 100%;
      height: 100%;
      content: "";
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;
      transition: all 0.3s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      &:before {
        transform: scale(1.07);
      }
    }
  }
}

#acquisition {
  .theme-content {
    background-color: $orange-umf;
    border: 3px solid $orange-medium-umf;

    &:before {
      background-image: url("./assets/img/homepage-acquisition.svg");
    }

    h2 {
      background-color: $orange-medium-umf;
    }
  }
}

#activation {
  .theme-content {
    background-color: $purple-umf;
    border: 3px solid $purple-medium-umf;

    &:before {
      background-image: url("./assets/img/homepage-activation.svg");
    }

    h2 {
      background-color: $purple-medium-umf;
    }
  }
}

#analytics {
  .theme-content {
    background-color: $pink-umf;
    border: 3px solid $pink-medium-umf;

    &:before {
      background-image: url("./assets/img/homepage-analytics.svg");
    }

    h2 {
      background-color: $pink-medium-umf;
    }
  }
}

#rgpd {
  .theme-content {
    background-color: $yellow-umf;
    border: 3px solid $yellow-medium-umf;

    &:before {
      background-image: url("./assets/img/homepage-rgpd.svg");
    }

    h2 {
      background-color: $yellow-medium-umf;
    }
  }
}

/* BREAKPOINTS */
@include media-breakpoint-up(lg) {
  body {
    padding-bottom: 90px;
    border-bottom: 68px solid $blue-umf;
  }

  #content {
    padding-top: 230px;
  }

  .theme {
    .theme-content {
      border-radius: 30px;
    }
  }
}
</style>
