<template>
  <header id="header">
    <div class="container h-100">
      <div class="row d-none d-md-flex h-100 align-items-center desktop">
        <div class="col-3 logo">
          <router-link :to="{ name: 'Homepage' }">
            <img
              alt="Site CRM"
              class="img-fluid"
              src="@/assets/img/logo-1.png"
            />
            <img
              alt="Site CRM"
              class="img-fluid"
              src="@/assets/img/logo-titre.png"
            />
          </router-link>
        </div>
        <nav class="col-9">
          <ul>
            <li v-for="(link, index) in nav.slice(0, 1)" :key="index">
              <router-link
                :data-ref="link.route_match"
                :to="{ name: link.component }"
                >{{ link.name }}
              </router-link>
            </li>
            <li>
              <a href="https://crm.universalmusic.fr/reglement/" target="_blank"
                >Regmaker</a
              >
            </li>
            <li v-for="(link, index) in nav.slice(1, nav.length)" :key="index">
              <router-link
                :data-ref="link.route_match"
                :to="{ name: link.component }"
                >{{ link.name }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="row d-md-none h-100 align-items-end mobile">
        <div class="offset-1 col-10">
          <div :class="{ active: burger }" class="burger" @click="clickBurger">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img alt="Site CRM" src="@/assets/img/logo-1.png" />
        </div>
      </div>
    </div>
  </header>
  <section v-if="burger" id="nav_mobile" class="container">
    <div class="row h-100">
      <nav class="col-12 h-50">
        <ul>
          <li v-for="(link, index) in nav" :key="index">
            <router-link :to="{ name: link.component }" @click="clickBurger"
              >{{ link.name }}
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="col-12 h-50">
        <img alt="Site CRM" src="@/assets/img/nav-mobile-illu.svg" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      nav: [
        {
          name: "Accueil",
          component: "Homepage",
          route_match: "Homepage",
        },
        {
          name: "Tutos",
          component: "TutosList",
          route_match: "Tutos",
        },
        {
          name: "Outils",
          component: "Outils",
          route_match: "Outils",
        },
        {
          name: "Lexique",
          component: "Lexique",
          route_match: "Lexique",
        },
        {
          name: "Contacts",
          component: "Contact",
          route_match: "Contact",
        },
      ],
      burger: false,
    };
  },
  methods: {
    clickBurger: function () {
      this.burger = !this.burger;
      document.body.style.overflow = this.burger ? "hidden" : "";
    },
  },
};
</script>

<style lang="scss">
#header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 111px;
  background: #ffffff;
  position: fixed;
  z-index: 3;
  top: 0;
  box-shadow: 0px 4px 30px #ededed;

  .desktop {
    nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      --ul-after-left: 0px;

      ul {
        display: flex;
        margin-bottom: 0;
        padding-left: 0;
        position: relative;

        li {
          list-style-type: none;
          margin-left: 35px;

          a {
            font-weight: 700;
            font-size: 2rem;
            color: $blue-umf;
            text-decoration: none;
          }

          &:first-child {
            margin-left: 0;
          }
        }

        &:after {
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background: $green-umf;
          position: absolute;
          left: var(--ul-after-left);
          bottom: 0;
          border-radius: 5px;
          transition: left 0.4s ease-in-out;
        }
      }
    }

    .logo {
      a {
        display: flex;
        align-items: center;

        img {
          width: 50px;

          & + img {
            width: 150px;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .mobile {
    padding-bottom: 15px;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .burger {
      position: relative;
      cursor: pointer;

      span {
        display: block;
        width: 24px;
        height: 6px;
        background: $blue-umf;
        border-radius: 5px;
        margin-bottom: 6px;

        &:last-child {
          width: 15px;
          position: absolute;
          left: 0;
          margin-bottom: 0;
          transition: all 0.3s ease-in-out;
        }
      }

      &.active {
        span:last-child {
          background: $green-umf;
          left: 9px;
        }
      }
    }
  }
}

#nav_mobile {
  height: calc(100% - 111px);
  background: #ffffff;
  position: fixed;
  top: 111px;
  text-align: center;
  z-index: 2;

  nav {
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      margin-bottom: 0;
      padding-left: 0;

      li {
        list-style-type: none;

        a {
          font-weight: 900;
          font-size: 3.8rem;
          line-height: 100%;
          color: $blue-umf;
          text-decoration: none;

          &:hover,
          &.router-link-active {
            color: $green-old-umf;
          }
        }
      }
    }
  }

  nav + div {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
      height: 75%;
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-up(lg) {
  #header {
    height: 140px;
  }
}
</style>
