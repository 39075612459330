<template>
  <div id="content" class="container">
    <div class="row">
      <div class="col-12">
        <a
          class="back"
          @click="this.$router.push({ path: this.$store.state.search_back })"
          >Retour</a
        >
        <h1>Contacts</h1>
      </div>
    </div>
    <div class="row cards">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="content_card">
          <h2>
            Capitol - Carthage - Panthéon - Barclay<br />
            Virgin - Vinyl Collector - Acquisition base UMGI
          </h2>
          <div class="contact_items">
            <ContactItem :contact="this.contacts[3]"></ContactItem>
            <ContactItem :contact="this.contacts[1]"></ContactItem>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="content_card">
          <h2>
            One Records - Romance - MCA - Decca<br />
            Island Def Jam - Blue Note - Activation base UMGI
          </h2>
          <div class="contact_items">
            <ContactItem :contact="this.contacts[2]"></ContactItem>
            <ContactItem :contact="this.contacts[0]"></ContactItem>
          </div>
        </div>
      </div>
      <div class="col-12 offset-md-4 col-md-4 offset-xl-0 col-xl-3 claire">
        <div class="content_card">
          <div class="contact_items">
            <ContactItem :contact="this.contacts[4]"></ContactItem>
          </div>
        </div>
      </div>
    </div>
    <div class="row activists">
      <h2>CRM Activists : Référents CRM au sein des équipes/labels</h2>
      <div class="col-12 activists-content">
        <div
          v-for="(activist, index) in activists"
          :key="index"
          class="activist"
        >
          <a :href="'mailto:' + activist.email">{{ activist.name }}</a>
          <span> - {{ activist.poste }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactItem from "@/components/ContactItem.vue";

export default {
  name: "Contact",
  data() {
    return {
      contacts: [
        {
          img: "pierre-charles.jpg",
          name: "Pierre-Charles Guy Sorba",
          poste: "Assistant Chef de projet CRM",
          email: "pierrecharles.guysorba@umusic.com",
        },
        {
          img: "marina.jpg",
          name: "Marina Wong",
          poste: "Assistante Cheffe de projet CRM",
          email: "marina.wong@umusic.com",
        },
        {
          img: "cloe.jpg",
          name: "Cloé Florville",
          poste: "Cheffe de projet CRM",
          email: "cloe.florville@umusic.com",
        },
        {
          img: "eva.jpg",
          name: "Eva Marcelat",
          poste: "Cheffe de projet CRM",
          email: "eva.marcelat@umusic.com",
        },
        {
          img: "claire.jpg",
          name: "Claire Cavallini",
          poste: "Directrice Data & Développement",
          email: "claire.cavallini@umusic.com",
        },
      ],
      activists: [
        {
          name: "Adeline Delgado",
          email: "adeline.delgado@umusic.com",
          poste: "Chef de projet e-commerce",
        },
        {
          name: "Adrien Henry	",
          email: "adrien.henry@umusic.com	",
          poste: "Chef de projet MCA",
        },
        {
          name: "Adrien Picard	",
          email: "adrien.picard@umusic.com	",
          poste:
            "Responsable Innovation & Marketing Digital MCA/Island Def Jam",
        },
        {
          name: "Alexandre Leclerc	",
          email: "alexandre.leclerc@umusic.com	",
          poste: "Directeur Général Label Decca/MCA",
        },
        {
          name: "Alice Mainfroy",
          email: "alice.mainfroy@umusic.com	",
          poste: "Chef de projet D2C",
        },
        {
          name: "Alperen Aslan",
          email: "alperen.aslan2@umusic.com",
          poste: "Chargé marketing digital Romance",
        },
        {
          name: "Amaury Feron",
          email: "amaury.feron@umusic.com",
          poste: "Directeur général adjoint One Records",
        },
        {
          name: "Anais Auzet	",
          email: "anais.auzet@umusic.com	",
          poste: "Directrice Marketing MCA",
        },
        {
          name: "Anais Hallier	",
          email: "anais.hallier@umusic.com	",
          poste: "Responsable stratégie Decca",
        },
        {
          name: "Angèle Baron",
          email: "angele.baron@umusic.com",
          poste: "Chef de projet Island Def Jam",
        },
        {
          name: "Angelina Paolini",
          email: "angelina.paolini@umusic.com",
          poste: "Responsable Innovation & Marketing Digital Panthéon/Barclay",
        },
        {
          name: "Aymeric Gaudelet",
          email: "aymeric.gaudelet@umusic.com",
          poste:
            "Chef de projet e-commerce (Virgin, Barclay, Panthéon, Decca et MCA)",
        },
        {
          name: "Benjamin Manaut	",
          email: "benjamin.manaut@umusic.com	",
          poste: "Responsable marketing One Records (inter)",
        },
        {
          name: "Bérangère Valles	",
          email: "berangere.valles@umusic.com	",
          poste: "Responsable marketing One Records (local)",
        },
        {
          name: "Bryan Bismuth",
          email: "bryan.bismuth@umusic.com	",
          poste: "Chef de projet One Records",
        },
        {
          name: "Carole Larue	",
          email: "carole.larue@umusic.com	",
          poste: "Chef de projet MCA",
        },
        {
          name: "Charles Combarel	",
          email: "charles.combarel@umusic.com	",
          poste: "Responsable Innovation & Marketing Digital Capitol",
        },
        {
          name: "Charlotte Remoleux	",
          email: "charlotte.remoleux@umusic.com	",
          poste: "Chef de projet Virgin",
        },
        {
          name: "Chloé Duclos	",
          email: "chloe.duclos2@umusic.com	",
          poste: "Chef de projet Island Def Jam",
        },
        {
          name: "Deborah Bernabe	",
          email: "deborah.bernabe@umusic.com	",
          poste: "Chef de projet MCA",
        },
        {
          name: "Janice Brami	",
          email: "janice.brami@umusic.com	",
          poste: "Responsable E-Commerce",
        },
        {
          name: "Jéremy Colas	",
          email: "jeremy.colas2@umusic.com	",
          poste: "Chef de projet Decca/MCA",
        },
        {
          name: "Jordan Reutenauer	",
          email: "jordan.reutenauer@umusic.com	",
          poste: "Chef de projet Carthage",
        },
        {
          name: "Jordane Gombault	",
          email: "jordane.gombault@umusic.com	",
          poste: "Directeur innovation marketing digital",
        },
        {
          name: "Julie Mycke	",
          email: "julie.mycke@umusic.com	",
          poste: "Chef de projet MCA",
        },
        {
          name: "Julien Deshayes	",
          email: "julien.deshayes@umusic.com	",
          poste: "Chef de projet MCA",
        },
        {
          name: "Karine Luntadila	",
          email: "karine.luntadila@umusic.com	",
          poste: "Chef de projet D2C",
        },
        {
          name: "Laurence Ferracci	",
          email: "laurence.ferracci@umusic.com	",
          poste: "Chef de projet Barclay",
        },
        {
          name: "Magdalena Huebner",
          email: "magdalena.huebner@umusic.com",
          poste: "Chef de projet Decca",
        },
        {
          name: "Mareva Jesel	",
          email: "mareva.jesel@umusic.com	",
          poste:
            "Responsable Innovation & Marketing Digital One Records/Carthage/Romance",
        },
        {
          name: "Margaux Bervas	",
          email: "margaux.bervas@umusic.com	",
          poste: "Chef de projet Panthéon",
        },
        {
          name: "Margot Laisman	",
          email: "margot.laisman@umusic.com	",
          poste: "Chef de projet e-commerce",
        },
        {
          name: "Marie Charlotte Marguerite	",
          email: "mariecharlotte.marguerite@umusic.com	",
          poste: "Chef de projet One Records",
        },
        {
          name: "Marie-Anne Chrétien 	",
          email: "marieanne.chretien@umusic.com	",
          poste: "Chef de projet Panthéon",
        },
        {
          name: "Marina Wong",
          email: "marina.wong@umusic.com",
          poste:
            "Assistante Cheffe de projet CRM (Virgin records, Virgin LAS, Carthage, Panthéon, Capitol, Barclay, Vinyl Collector)",
        },
        {
          name: "Mélanie Fiton",
          email: "melanie.fiton@umusic.com",
          poste: "Chargée de développement & contenus",
        },

        {
          name: "Pascal Bod	",
          email: "pascal.bod@umusic.com	",
          poste: "Directeur Marketing Decca",
        },
        {
          name: "Patrick Yamine	",
          email: "patrick.yamine@umusic.com	",
          poste: "Directeur Buisiness MCA",
        },
        {
          name: "Pierre-Charles Guy Sorba",
          email: "pierrecharles.guysorba@umusic.com",
          poste:
            "Assistant Chef de projet CRM (One Records, Island Def Jam, Decca, MCA, Romance, Blue Note)",
        },
        {
          name: "Pierre Rimbaud	",
          email: "pierre.rimbaud@romance-musique.com	",
          poste: "Chef de projet Romance",
        },
        {
          name: "Pierre Schoutteten	",
          email: "pierre.schoutteten@umusic.com	",
          poste: "Chef de projet One Records",
        },
        {
          name: "Quentin Davenas	",
          email: "quentin.davenas2@umusic.com	",
          poste: "Chef de projet/Directeur Artistique One Records",
        },
        {
          name: "Romain Constant",
          email: "romain.constant@umusic.com",
          poste: "Directeur marketing Island Def Jam (inter)",
        },
        {
          name: "Romain Leclerc",
          email: "romain.leclercq@umusic.com",
          poste: "Chef de projet Island Def Jam",
        },
        {
          name: "Roselaine Boudjellal	",
          email: "roselaine.boudjellal@umusic.com	",
          poste: "Chef de projet Capitol",
        },
        {
          name: "Ryan Lavoine",
          email: "ryan.lavoine@umusic.com",
          poste: "Chef de projet Virgin",
        },
        {
          name: "Samantha Bedran	",
          email: "samantha.bedran@umusic.com	",
          poste: "Attaché de Presse/ Chargé Marketing MCA",
        },
        {
          name: "Sebastien Eyzat	",
          email: "sebastien.eyzat@umusic.com	",
          poste: "Responsable Marketing E-Commerce",
        },
        {
          name: "Simon Fraudeau",
          email: "simon.fraudeau@umusic.com",
          poste: "Directeur marketing Island Def Jam (local)",
        },
        {
          name: "Sofiane Zeggaoui",
          email: "sofiane.zeggaoui@umusic.com",
          poste: "Chef de projet Decca",
        },
        {
          name: "Thomas Lescan Du Plessix Orsel	",
          email: "thomas.duplessix@umusic.com	",
          poste: "Chef de projet D2C",
        },
        {
          name: "Tom Poinssonnet	",
          email: "tom.poinssonnet@umusic.com	",
          poste: "Directeur Artistique",
        },
        {
          name: "Yoan Corantin",
          email: "yoan.corantin@umusic.com",
          poste: "Chef de projet Island Def Jam",
        },
      ],
    };
  },
  components: {
    ContactItem,
  },
  mounted() {
    this.$store.state.app_id = "contact";
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.state.search_back = from.fullPath;
    });
  },
};
</script>

<style lang="scss">
#contact {
  #content {
    padding-top: 180px;

    & > div:first-child {
      background: url("../assets/img/contact-background.svg") no-repeat bottom
        right;
    }
  }

  h1 {
    font-weight: 900;
    line-height: 103%;
    color: $blue-umf;
    margin-bottom: 40px;
  }

  .cards {
    .content_card {
      //display: inline-block;
      background-color: #eeeded;
      margin-bottom: 16px;
      padding: 20px;
      border-radius: 30px;

      h2 {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        color: $blue-umf;
        margin-bottom: 15px;
      }
    }

    .claire {
      .contact_card {
        width: 100%;
      }
    }
  }

  .activists {
    background-color: #ededed;
    color: $blue-umf;
    padding: 33px;

    h2 {
      margin-bottom: 20px;
    }

    .activists-content {
      background-color: #ffffff;
      border-radius: 30px;
      padding: 26px 32px;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 133%;

      .activist {
        margin-bottom: 10px;
      }

      a {
        font-weight: 500;
        font-size: 1.7rem;
        color: $blue-umf;
        line-height: 140%;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #contact {
    #content {
      & > div:first-child {
        background-size: contain;
        margin-bottom: 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #contact {
    .contact_card {
      display: flex;

      .img {
        width: 96px;
        height: auto;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #contact {
    .contact_card {
      .infos {
        //height: calc(100% - 230px);
      }
    }

    .activists {
      border-radius: 30px;
      padding: 50px;

      .activists-content {
        padding: 38px 78px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #contact {
    #content {
      & > div:first-child {
        padding-bottom: 40px;
      }
    }

    .contact_items {
      display: flex;
    }

    h1 {
      margin-bottom: 100px;
    }

    .cards {
      position: relative;
      top: -20px;
    }
  }
}

@include media-breakpoint-up(xl) {
  #contact {
    .claire {
      padding-top: 85px;
    }
  }
}
</style>
