<template>
  <div id="content" class="container">
    <div id="searchbar" class="row">
      <div
        v-if="this.$route.name === 'TutosSearch'"
        class="col-12 d-none d-lg-flex"
      >
        <a class="back" @click="back">Retour</a>
      </div>
      <form
        class="offset-1 col-10 offset-lg-0 col-lg-12"
        @submit.prevent="submit"
      >
        <input
          v-model="input_search"
          placeholder="Rechercher dans les tutos UMF"
          type="text"
        />
        <button
          :disabled="!input_search"
          class="search-action"
          type="submit"
        ></button>
      </form>
    </div>
    <div class="row">
      <div
        id="crm_zone"
        class="col-12 col-md-6 theme"
        @click="
          this.$router.push({
            name: 'TutosList',
          })
        "
      >
        <div class="theme-content">
          <div>
            <h1>Tous vos tutos CRM</h1>
            <h2>Découvrez des tutos pour vous aider dans vos opés CRM.</h2>
            <div>
              <router-link :to="{ name: 'TutosList' }"
                >Liste des tutos
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row h-100 themes">
          <div
            id="acquisition"
            class="col-6 theme"
            @click="
              this.$router.push({
                name: 'TutosList',
                params: { categorie: 'acquisition' },
              })
            "
          >
            <div class="theme-content"><h2>Acquisition</h2></div>
          </div>
          <div
            id="activation"
            class="col-6 theme"
            @click="
              this.$router.push({
                name: 'TutosList',
                params: { categorie: 'activation' },
              })
            "
          >
            <div class="theme-content"><h2>Activation</h2></div>
          </div>
          <div
            id="analytics"
            class="col-6 theme"
            @click="
              this.$router.push({
                name: 'TutosList',
                params: { categorie: 'analytics' },
              })
            "
          >
            <div class="theme-content"><h2>Analytics</h2></div>
          </div>
          <div
            id="rgpd"
            class="col-6 theme"
            @click="
              this.$router.push({
                name: 'TutosList',
                params: { categorie: 'rgpd' },
              })
            "
          >
            <div class="theme-content"><h2>RGPD</h2></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homepage",
  data() {
    return {
      input_search: "",
    };
  },
  mounted() {
    this.$store.state.app_id = "homepage";
  },
  methods: {
    submit: function (e) {
      if (e.target.classList.contains("clear")) {
        this.input_search = "";
        return this.$router.push({
          name: "TutosSearch",
        });
      } else {
        return this.$router.push({
          name: "TutosSearch",
          query: { q: this.input_search },
        });
      }
    },
  },
  watch: {
    input_search() {
      document.getElementsByTagName("form")[0].classList.remove("clear");
    },
  },
};
</script>

<style lang="scss">
.themes {
  .theme {
    .theme-content {
      text-align: center;
    }
  }

  h2 {
    display: inline-block;
    font-weight: 700;
    line-height: 140%;
    padding: 5px 25px;
    border-radius: 30px;
    color: #ffffff;
  }
}

#homepage {
  .theme {
    .theme-content {
      height: var(--ref-width-col-6);
      cursor: pointer;
      padding: 14px 0;
      border-radius: 10px;

      &:before {
        background-position: center bottom;
        transform-origin: bottom center;
      }
    }
  }

  #acquisition {
    padding-bottom: var(--bs-gutter-x);
  }

  #activation {
    padding-bottom: var(--bs-gutter-x);
  }
}

#crm_zone {
  &.theme {
    margin-bottom: var(--bs-gutter-x);

    .theme-content {
      height: var(--ref-width-col-12);
      background-color: $green-umf;
      border: 3px solid $green-old-umf;
      color: $blue-umf;

      &:before {
        background-image: url("../assets/img/homepage-crm-zone.svg");
        background-position: center right;
        transform-origin: top right;
        background-size: 40%;
      }

      &:hover {
        &:before {
          transform: scale(1.03);
        }
      }

      & > div {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        padding: 30px 40% 30px 30px;
      }

      h1 {
        font-weight: 900;
        line-height: 103%;
      }

      h2 {
        & + div {
          margin-top: auto;
        }
      }

      a {
        background-color: $blue-umf;
        border-radius: 10px;
        padding: 13px 24px;
        font-size: 1.7rem;
        font-weight: 700;
        line-height: 140%;
        color: #ffffff;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: $blue-umf;
        }
      }
    }
  }
}

/* BREAKPOINTS */
@include media-breakpoint-down(lg) {
  #homepage {
    #content {
      padding-top: 111px;
      max-width: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  #homepage {
    .theme {
      .theme-content {
        height: calc(
          var(--ref-width-col-6) / 2 - calc(var(--bs-gutter-x) * 0.5)
        );
      }
    }
  }

  #crm_zone {
    &.theme {
      margin-bottom: auto;

      .theme-content {
        height: var(--ref-width-col-6);

        h2 {
          font-size: 1.8rem;
        }

        a {
          background-color: $green-old-umf;
        }
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  #crm_zone {
    &.theme {
      .theme-content {
        h1 {
          font-size: 5rem !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #homepage {
    .theme {
      .theme-content {
        padding: 32px 0;
        border-radius: 30px;
      }
    }
  }

  #crm_zone {
    &.theme {
      .theme-content {
        & > div {
          padding: 60px 40% 60px 60px;
        }

        a {
          background-color: $green-old-umf;
          font-size: 2.5rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #crm_zone {
    &.theme {
      .theme-content {
        &:before {
          background-size: auto;
        }

        h2 {
          font-size: 2.5rem;
        }
      }
    }
  }
}
</style>
