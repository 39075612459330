<template>
  <div id="content">
    <div v-if="this.tuto" class="container">
      <div class="row">
        <div class="col-12">
          <a
            class="back"
            @click="this.$router.push(this.$store.state.search_back)"
            >Retour</a
          >
          <h3 :class="this.tuto.categories[0].slug">
            {{ this.tuto.categories[0].name }}
          </h3>
          <h1 v-html="this.tuto.title.rendered"></h1>
        </div>
      </div>
    </div>
    <div v-if="this.tuto" class="container-fluid">
      <div class="row tuto">
        <div class="col-md-2 col-lg-3"></div>
        <div
          class="col-md-8 col-lg-6 tuto-content"
          v-html="this.tuto.content.rendered"
        ></div>
        <div class="col-md-2 col-lg-3"></div>
      </div>
    </div>
    <div v-if="similaires.length > 0" id="similaires" class="container">
      <div class="row">
        <div class="col-12">
          <h1>Ces articles pourraient vous intéresser !</h1>
          <div class="similaires">
            <div
              v-for="similaire in similaires"
              :key="similaire.id"
              class="similaire"
            >
              <h3 :class="similaire.categories[0].slug">
                {{ similaire.categories[0].name }}
              </h3>
              <a
                :href="
                  this.$router.push({
                    name: 'TutoView',
                    params: { slug: similaire.slug },
                  })
                "
                v-html="similaire.title.rendered"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutoView",
  data() {
    return {
      tuto: null,
      similaires: [],
    };
  },
  methods: {
    getTuto: async function () {
      const slug = this.$route.params.slug;
      const post = await this.axios.get("/posts?slug=" + slug);
      return post.data[0];
    },
    getCategorie: async function (id) {
      const query = "/categories?post=" + id;
      const response = await this.axios.get(query);
      return response.data;
    },
    getSimilaires: async function (tag_id) {
      const query = "/posts?tags=" + tag_id;
      const response = await this.axios.get(query);
      return response.data;
    },
  },
  mounted: async function () {
    this.$store.state.app_id = "tuto";
    const tutos = this.getTuto();

    await Promise.all([tutos]).then((tuto) => {
      this.getCategorie(tuto[0].id).then((categorie) => {
        tuto[0].categories = categorie;
        this.tuto = tuto[0];

        if (tuto[0].tags.length > 0) {
          this.getSimilaires(tuto[0].tags[0]).then((similaires) =>
            Promise.all(
              [...similaires]
                .sort(() => 0.5 - Math.random())
                .filter((similaire, index) => {
                  if (index < 3) {
                    this.getCategorie(similaire.id).then((categorie) => {
                      similaire.categories = categorie;
                      this.similaires.push(similaire);
                    });
                  }
                })
            )
          );
        }
      });
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.state.search_back = from.fullPath;
    });
  },
};
</script>

<style lang="scss">
#tuto {
  h1 {
    font-weight: 900;
    font-size: 3.8rem;
    line-height: 103%;
    color: $blue-umf;
    margin-bottom: 130px;
  }

  h3 {
    display: inline-block;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 140%;
    color: #ffffff;
    padding: 5px 17px;
    border-radius: 30px;
    margin-bottom: 20px;

    &.acquisition {
      background-color: $orange-medium-umf;
    }

    &.activation {
      background-color: $purple-medium-umf;
    }

    &.analytics {
      background-color: $pink-medium-umf;
    }

    &.rgpd {
      background-color: $yellow-medium-umf;
    }
  }

  .tuto {
    & > div {
      background-color: $grey-umf;

      &:first-child {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }

      &:last-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }
    }

    .tuto-content {
      background-color: #ffffff;
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 140%;
      color: #292929;
      overflow-wrap: break-word;
      text-align: justify;

      .is-layout-flex {
        display: flex;
        margin-bottom: 30px;

        &.is-content-justification-center {
          align-items: center;
          justify-content: center;
        }
      }

      .custom-styles {
        &.btn {
          border-radius: 20px;
          padding: 4px 10px;
          margin: 0 10px;

          &.black {
            background-color: #0c0c0c;

            a {
              color: #ffffff;
              text-decoration: none;
            }
          }
        }
      }

      ul {
        margin-bottom: 30px;

        ul {
          margin-bottom: 5px;
        }
      }

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 140%;
        color: #292929;
        margin: 20px 0;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 140%;
        color: #292929;
        margin-bottom: 20px;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }

      a {
        color: $green-old-umf;
      }

      .has-text-align-center {
        text-align: center;
      }

      iframe {
        width: 100%;
      }
    }
  }

  #similaires {
    h1 {
      margin: 120px 0 80px;
    }
  }

  .similaires {
    display: flex;
    justify-content: space-between;

    .similaire {
      width: 375px;
      background: #ededed;
      padding: 25px;
      border-radius: 30px;
      margin-bottom: 15px;

      a {
        display: block;
        font-weight: 700;
        font-size: 2rem;
        line-height: 140%;
        color: $blue-umf;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #tuto {
    .similaires {
      flex-direction: column;
      align-items: center;
    }
  }
}

@include media-breakpoint-up(md) {
  #tuto {
    h1 {
      font-size: 5rem;
    }

    .tuto {
      .tuto-content {
        padding: 0 36px;
      }
    }

    .similaires {
      .similaire {
        margin: 0 15px 15px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #tuto {
    h1 {
      font-size: 8rem;
    }

    .tuto {
      .tuto-content {
        font-size: 2rem;

        p {
          font-size: 2rem;
        }
      }
    }
  }
}
</style>
