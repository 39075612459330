<script>
export default {
  name: "ContactItem",
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="contact_card">
    <div
      :style="
        'background-image: url(' +
        require(`@/assets/medias/${contact.img}`) +
        ')'
      "
      class="img"
    ></div>
    <div class="infos">
      <p>{{ contact.name }}</p>
      <p>{{ contact.poste }}</p>
      <div class="email">
        <!--        <a :href="'mailto:' + contact.email">{{ contact.email }}</a>-->
        <a :href="'mailto:' + contact.email"
          ><i class="bi bi-chat-right-heart"></i> Contacter</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contact_card {
  background: $blue-umf;
  border-radius: 20px;
  padding: calc(var(--bs-gutter-x) * 0.5);
  margin-right: 8px;

  .img {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }

  .infos {
    display: flex;
    flex-direction: column;
    padding: 23px;

    p {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 133%;
      color: $green-umf;
      margin: 0;

      &:first-child {
        height: 40px;
      }

      & + p {
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 30px;
      }
    }

    .email {
      //margin-top: auto;

      a {
        background: $green-umf;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 133%;
        color: $blue-umf;
        border-radius: 21px;
        text-decoration: none;
        padding: 3px 11px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .contact_card {
    margin-bottom: 6px;
  }
}

@include media-breakpoint-up(sm) {
  .contact_card {
    margin-bottom: 12px;
  }
}

@include media-breakpoint-up(md) {
}

@include media-breakpoint-up(lg) {
  .contact_card {
    width: 50%;
  }
}
</style>
