<template>
  <div id="content" class="container">
    <div id="searchbar" class="row">
      <div
        v-if="this.$route.name === 'TutosSearch'"
        class="col-12 d-none d-lg-flex"
      >
        <a class="back" @click="back">Retour</a>
      </div>
      <form
        class="offset-1 col-10 offset-lg-0 col-lg-12"
        @submit.prevent="submit"
      >
        <input
          v-model="input_search"
          placeholder="Rechercher dans les tutos UMF"
          type="text"
        />
        <button
          :disabled="!input_search"
          class="search-action"
          type="submit"
        ></button>
      </form>
    </div>
    <div class="row">
      <div
        v-if="this.$route.name === 'TutosSearch'"
        class="offset-1 col-10 offset-lg-0 col-lg-12 d-lg-none"
      >
        <a class="back" @click="back">Retour</a>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Tutos",
  data() {
    return {
      route_back: null,
      input_search: "",
    };
  },
  methods: {
    back: function () {
      this.input_search = "";
      return this.$router.push({ path: this.$store.state.search_back });
    },
    submit: function (e) {
      if (e.target.classList.contains("clear")) {
        this.input_search = "";
        return this.$router.push({
          name: "TutosSearch",
        });
      } else {
        return this.$router.push({
          name: "TutosSearch",
          query: { q: this.input_search },
        });
      }
    },
  },
  watch: {
    input_search() {
      document.getElementsByTagName("form")[0].classList.remove("clear");
    },
  },
  mounted() {
    this.route_back =
      this.$route.name === "TutosSearch" ? "TutosList" : this.$route.name;
    this.input_search = this.$route.query.q ?? "";
  },
};
</script>

<style lang="scss">
#searchbar {
  margin-bottom: 30px;

  form {
    position: relative;

    &.clear {
      .search-action {
        background-color: $pink-medium-umf;

        &:after {
          background-color: $pink-old-umf;
          mask-image: url("../../assets/img/search-clear.svg");
          mask-position: center center;
        }

        &:hover {
          background-color: $pink-old-umf;

          &:after {
            background-color: $pink-medium-umf;
          }
        }
      }
    }

    input {
      width: 100%;
      height: 60px;
      border: 3px solid $blue-umf;
      border-radius: 100px;
      padding: 0 68px 0 32px;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 133%;
      color: $blue-umf;

      &::placeholder {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 133%;
        color: $blue-umf;
      }
    }

    .search-action {
      display: flex;
      width: 48px;
      height: 48px;
      background-color: $green-umf;
      border-radius: 100px;
      border: none;
      transition: all 0.3s ease-in-out;
      padding: 0;
      position: absolute;
      top: 6px;
      right: 14px;

      &:after {
        width: 100%;
        height: 100%;
        content: "";
        background-color: #ffffff;
        mask-image: url("../../assets/img/search-paper-plane.svg");
        mask-repeat: no-repeat;
        mask-position: left 17px center;
      }

      &:hover {
        background-color: $blue-umf;
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }
}

.back {
  display: flex;
  background: url("../../assets/img/arrow-back.svg") no-repeat left center;
  font-weight: 700;
  font-size: 2rem;
  line-height: 140%;
  margin-bottom: 28px;
  color: $blue-umf;
  text-decoration: none;
  padding-left: 20px;
  cursor: pointer;

  &:hover {
    color: $blue-umf;
  }
}

/* BREAKPOINTS */
@include media-breakpoint-down(lg) {
  #searchbar {
    width: 100%;
    background-color: #ffffff;
    position: fixed;
    z-index: 1;
    box-shadow: 0px 30px 30px 0px #ededed;
    padding: 20px 0 30px;

    form {
      input {
        width: calc(100% - 56px);
        height: 48px;
      }

      .search-action {
        top: 0;
        right: 8px;
      }
    }

    & + div {
      margin-top: 130px;
    }
  }

  #tutos,
  #search {
    #header {
      box-shadow: none;
    }

    #content {
      max-width: 100%;
      margin: 0;
      padding-top: 111px;
    }
  }
}

@include media-breakpoint-up(lg) {
  #tutos {
    #content > div > div.row {
      margin-bottom: var(--bs-gutter-x);
    }
  }
}
</style>
