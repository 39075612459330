<template>
  <div v-if="!loader" class="row">
    <div class="offset-1 col-10 offset-lg-0 col-lg-12 resultat">
      <h1 v-if="clear_search">Faites votre recherche</h1>
      <h1 v-else>
        Résultat de la<br />
        recherche “<span v-html="this.$route.query.q"></span>”
      </h1>
      <h2>{{ tutos_search.length }} resultats trouvés</h2>
    </div>
    <div v-if="!clear_search" class="col-12 tutos">
      <div v-for="(tuto, index) in tutos_search" :key="index" class="row tuto">
        <div class="col-12 col-lg-3">
          <h3
            :class="tuto.categories[0].slug"
            v-html="tuto.categories[0].name"
          ></h3>
        </div>
        <div class="col-12 col-lg-9">
          <p class="title" v-html="tuto.title.rendered"></p>
          <p class="excerpt" v-html="truncate(tuto.excerpt.rendered, 200)"></p>
          <router-link :to="{ name: 'TutoView', params: { slug: tuto.slug } }"
            >Poursuivre la lecture
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-12">
      <span class="top" @click.prevent="scrollTop"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutosSearch",
  data() {
    return {
      loader: true,
      tutos_search: [],
      clear_search: false,
    };
  },
  methods: {
    scrollTop: function () {
      window.scrollTo(0, 0);
    },
    truncate: function (text, length, clamp) {
      if (text.length > length) {
        clamp = clamp || "...";
        const trimmedString = text.substr(0, length);
        return (
          trimmedString.substr(
            0,
            Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
          ) + clamp
        );
      }

      return text;
    },
    getSearch: async function () {
      const query = "/search?subtype=post&search=" + this.$route.query.q;
      const response = await this.axios.get(query);
      return response.data;
    },
    getPost: async function (id) {
      const query = "/posts/" + id;
      const response = await this.axios.get(query);
      return response.data;
    },
    getCategorie: async function (id) {
      const query = "/categories?post=" + id;
      const response = await this.axios.get(query);
      return response.data;
    },
    search: async function () {
      this.loader = true;
      this.tutos_search = [];
      this.clear_search = false;
      const search = await this.getSearch();

      for (let i = 0; i < search.length; i++) {
        const id = search[i].id;
        const post = await this.getPost(id);
        const categories = await this.getCategorie(id);
        if (categories.length > 0) {
          post.categories.length = 0;
          post.categories = categories;
          this.tutos_search.push(post);
        }
      }

      document.getElementsByTagName("form")[0].classList.add("clear");
      this.loader = false;
    },
  },
  watch: {
    "$route.query.q"(newSearch) {
      if (newSearch === undefined) {
        this.tutos_search = [];
        this.clear_search = true;
      } else {
        document.getElementsByTagName("form")[0].classList.add("clear");
        this.search();
      }
    },
  },
  mounted() {
    this.$store.state.app_id = "search";
    this.search();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.fullPath !== "/") {
        vm.$store.state.search_back = from.fullPath;
      }
    });
  },
};
</script>

<style lang="scss">
#search {
  .resultat {
    background: url("../../assets/img/nav-mobile-illu.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 25%;

    h1 {
      color: $blue-umf;
      font-weight: 900;
      line-height: 103%;
      margin: 10px 0 40px;
    }

    h2 {
      font-weight: 700;
      font-size: 2rem;
      line-height: 140%;
      color: $blue-umf;
      margin-bottom: 53px;
    }
  }

  .tutos {
    background-color: #ededed;
    padding: 50px;

    .tuto {
      display: flex;
      background-color: #ffffff;
      border-radius: 30px;
      padding: 30px;
      margin-bottom: 20px;

      h3 {
        display: inline-block;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 140%;
        color: #ffffff;
        padding: 5px 17px;
        border-radius: 30px;

        &.acquisition {
          background-color: $orange-medium-umf;
        }

        &.activation {
          background-color: $purple-medium-umf;
        }

        &.analytics {
          background-color: $pink-medium-umf;
        }

        &.rgpd {
          background-color: $yellow-medium-umf;
        }
      }

      p {
        color: $blue-umf;
        overflow-wrap: break-word;

        &.title {
          font-weight: 700;
          font-size: 1.7rem;
          line-height: 140%;
        }

        &.excerpt {
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 133%;
          margin-bottom: 0;
        }
      }

      a {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 133%;
        text-decoration: none;
        color: $blue-umf;
      }
    }
  }

  .top {
    display: block;
    width: 72px;
    height: 72px;
    background: $green-umf url("../../assets/img/arrow-top.svg") no-repeat
      center center;
    cursor: pointer;
    margin: 33px auto 0;
    border-radius: 72px;
  }
}

@include media-breakpoint-down(lg) {
  #search {
    .tutos {
      .tuto {
        h3 {
          margin-bottom: 15px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #search {
    .resultat {
      background-position: center right;
    }
  }
}

@include media-breakpoint-up(lg) {
  #search {
    .resultat {
      background-size: 20%;
    }

    .tutos {
      border-radius: 30px;

      .tuto {
        h3 {
          font-size: 2.5rem;
          padding: 5px 25px;
        }

        p {
          &.title {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #search {
    .resultat {
      background-size: 20%;
    }

    .tutos {
      border-radius: 30px;

      .tuto {
        h3 {
          font-size: 2.5rem;
          padding: 5px 25px;
        }

        p {
          &.title {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #search {
    .resultat {
      background-size: 15%;
    }
  }
}
</style>
