<template>
  <div v-if="tutos.length > 0">
    <div
      v-for="(tuto, index) in tutos"
      :id="tuto.categorie_slug"
      :key="index"
      class="row"
    >
      <div class="offset-1 col-10 offset-lg-0 col-lg-6 theme">
        <img
          v-show="index > 0"
          alt=""
          class="d-lg-none"
          src="../../assets/img/tutos-mobile-arrow.svg"
        />
        <div class="theme-content">
          <h2>{{ tuto.categorie_name }}</h2>
        </div>
      </div>
      <div class="col-12 col-lg-6 tutos">
        <div class="h-100 tuto-content">
          <h2>
            Les tutos
            {{ tuto.categorie_name === "Rgpd" ? "RGPD" : tuto.categorie_name }}
          </h2>
          <h5>{{ tutos_subtitles[index] }}</h5>
          <div class="tutos-list">
            <div class="inside">
              <div v-for="post in tuto.posts" :key="post.index" class="tuto">
                <router-link
                  :to="{ name: 'TutoView', params: { slug: post.slug } }"
                  v-html="post.title.rendered"
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TutosList",
  data() {
    return {
      tutos_base: [],
      tutos: [],
      tutos_subtitles: [
        "Comment paramétrer une campagne de recrutement ?",
        "Comment paramétrer un envoi ?",
        "Comment consulter les résultats d’une campagne ?",
        "Connaître la réglementation en vigueur.",
      ],
    };
  },
  methods: {
    checkTutosScrollable: function () {
      const tutos = document.querySelectorAll(".tutos-list");
      tutos.forEach((tuto) => {
        tuto.classList.remove("scrollable");
        const inside = tuto.querySelector(".inside");
        if (inside.clientHeight > tuto.clientHeight) {
          tuto.classList.add("scrollable");
        }
      });
    },
    getCategories: async function (categorie) {
      const url = categorie ? "/categories?search=" + categorie : "/categories";
      const categories = await this.axios.get(url);
      return categories.data;
    },
    getPosts: async function (id) {
      const posts = await this.axios.get("/posts?per_page=30&categories=" + id);
      const array_sticky = [],
        array_others = [];

      posts.data.forEach((post) => {
        post.sticky ? array_sticky.push(post) : array_others.push(post);
      });

      posts.data = array_sticky.concat(array_others);
      return posts.data;
    },
  },
  mounted: async function () {
    this.$store.state.app_id = "tutos";
    window.addEventListener("resize", this.checkTutosScrollable);
    // Load datas
    this.tutos_base.push(
      ...(await this.getCategories(this.$route.params.categorie).then((data) =>
        Promise.all(
          [...data]
            .filter((categorie) => categorie.count > 0)
            .map(async (categorie) => ({
              categorie_name: categorie.name,
              categorie_slug: categorie.slug,
              posts: await this.getPosts(categorie.id),
            }))
        )
      ))
    );

    this.tutos = this.tutos_base;
    await this.$nextTick();
    this.checkTutosScrollable();
  },
};
</script>

<style lang="scss">
#tutos {
  .theme {
    .theme-content {
      padding: 15px 15px 50px 15px;
    }

    h2 {
      display: inline-block;
      font-weight: 700;
      line-height: 140%;
      padding: 5px 25px;
      border-radius: 30px;
      color: #ffffff;
    }
  }

  .tutos {
    .tuto-content {
      background-color: #ededed;

      h2 {
        font-weight: 700;
        line-height: 140%;
        color: $blue-umf;
      }

      h5 {
        font-weight: 700;
        line-height: 140%;
        font-size: 1.8rem;
        color: $blue-umf;
        margin-bottom: 34px;
      }
    }

    .tutos-list {
      &.scrollable {
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 11px;
          background-color: #ffffff;
          border-radius: 41px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $blue-umf;
          border-radius: 41px;
        }
      }

      .tuto {
        cursor: pointer;
        margin-bottom: 10px;
        border-radius: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: block;
          width: 100%;
          background-color: #ffffff;
          font-weight: 700;
          line-height: 140%;
          color: $blue-umf;
          margin-bottom: 0;
          padding: 7px 23px;
          border-radius: 10px;
          transition: width 0.3s ease-in-out;
          text-decoration: none;
        }
      }
    }
  }
}

/* BREAKPOINTS */
@include media-breakpoint-down(lg) {
  #tutos {
    .theme {
      & > img {
        display: block;
        width: 34px;
        margin: 30px auto;
      }

      .theme-content {
        overflow: hidden;

        &:before {
          background-position: center right;
          background-size: contain;
          transform: scale(2);
          transform-origin: right center;
        }
      }
    }

    .tutos {
      padding: 0;
      margin-top: 30px;

      .tuto-content {
        padding: 22px 33px;

        h2 {
          font-size: 1.7rem;
        }
      }

      .tutos-list {
        .tuto {
          a {
            background: #ffffff
              url("../../assets/img/tutos-mobile-grey-eye.svg") no-repeat right
              10px center;
            font-size: 1.5rem;
            padding-right: 41px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #tutos {
    .theme {
      .theme-content {
        &:before {
          transform: scale(1);
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #content {
    padding-top: 180px;
  }

  #tutos {
    .theme {
      .theme-content {
        height: var(--ref-width-col-6);
        padding: 38px;
      }
    }

    .tutos {
      .tuto-content {
        border-radius: 30px;
        padding: 45px 40px;
      }

      .tutos-list {
        max-height: calc(var(--ref-width-col-6) - 69px - 90px - 30px);
        padding-right: 20px;

        .tuto {
          background: $blue-umf url("../../assets/img/tuto-eye.svg") no-repeat
            right 6px center;

          a {
            font-size: 1.8rem;
          }

          &:hover {
            a {
              width: calc(100% - 36px);
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  #tutos {
    .tutos {
      .tutos-list {
        .tuto {
          a {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
</style>
