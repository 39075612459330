<template>
  <div id="content" class="container">
    <div class="row">
      <div class="offset-1 col-10 offset-md-4 col-md-4 login">
        <a :href="login" class="btn">Se connecter</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  computed: {
    login() {
      return process.env.VUE_APP_LOGIN;
    },
  },
  mounted() {
    this.$store.state.app_id = "login";
  },
};
</script>

<style lang="scss">
#login {
  #header .desktop nav {
    display: none;
  }

  .login {
    display: flex;
    justify-content: center;
    background-color: #ededed;
    color: $blue-umf;
    padding: 33px;
    border-radius: 30px;

    .btn {
      background-color: $blue-umf;
      color: #ffffff;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 140%;
    }
  }
}
</style>
